<template>
  <div class="member-manage">
    <div class="df-row">
      <div class="df-col">
        <el-select
          v-if="authType == 'H'"
          v-model="submitData.stores_guids"
          collapse-tags
          size="mini"
          multiple
          placeholder="请选择门店"
        >
          <el-option
            v-for="item in storesData"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-date-picker
          v-model="multipleDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <!-- <div class="df-col">
        <el-select
          v-model="submitData.work_type"
          placeholder="选择主题"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in workTypeList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.empl_guid"
          placeholder="选择回访人"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in emplList"
            :key="item.EmplGuid"
            :label="item.EmplName"
            :value="item.EmplGuid"
          >
          </el-option>
        </el-select>
      </div>

      <div class="df-col">
        <el-select
          v-model="submitData.status"
          placeholder="选择类别"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in statusList"
            :key="item.Value"
            :label="item.Name"
            :value="item.Value"
          >
          </el-option>
        </el-select>
      </div> -->

      <!-- <div class="df-col">
        <el-input v-model="submitData.word" size="mini" clearable></el-input>
      </div> -->

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearch"
          >查 询</el-button
        >
      </div>

      <div class="df-col">
        <el-button type="primary" size="mini" @click="onShowAdd"
          >临时客情</el-button
        >
      </div>
    </div>

    <div class="df-row">
      <div
        class="type-item"
        :class="item.WorkType === submitData.work_type ? 'active' : ''"
        v-for="(item, index) in categoryList"
        :key="index"
        @click="onSelectType(item.WorkType)"
      >
        <div class="top"></div>

        <div class="content-box">
          <div class="left">
            <span
              class="dfWebIcon"
              :class="item.Icon"
              :style="{ color: item.Color }"
            ></span>
            <span class="name">{{ item.WorkTypeText }}</span>
          </div>

          <div class="right">
            <div class="item">
              <span class="title">待分配</span>
              <span class="value">{{ item.UnAssign.Count }}</span>
            </div>
            <div class="item">
              <span class="title">待处理</span>
              <span class="value">{{ item.Pending.Count }}</span>
            </div>
            <div class="item">
              <span class="title">已处理</span>
              <span class="value">{{ item.Complete.Count }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- start 积分兑换记录 -->
    <el-table
      :data="tableData"
      :max-height="tableMaxHeight"
      size="mini"
      border
      stripe
      fit
      v-loading="loading"
    >
      <el-table-column prop="WorkTypeText" label="主题"> </el-table-column>
      <el-table-column prop="MemberName" label="客户"> </el-table-column>
      <el-table-column prop="MemberPhone" label="手机"> </el-table-column>
      <el-table-column prop="MemberTagNames" label="标签"> </el-table-column>
      <el-table-column prop="FollowEmplNames" label="跟进人"> </el-table-column>
      <el-table-column label="回访人">
        <template slot="header" slot-scope="scope">
          <el-dropdown placement="bottom" trigger="click">
            <span class="el-dropdown-link">
              回访人<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in emplList" :key="index">
                <div class="status" @click="onSelectEmpl(item)">
                  {{ item.EmplName }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">{{ scope.row.AccepterName }}</template>
      </el-table-column>
      <el-table-column prop="" label="状态">
        <template slot="header" slot-scope="scope">
          <el-dropdown placement="bottom" trigger="click">
            <span class="el-dropdown-link">
              {{ `状态(${statusList[submitData.status].Name})`
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in statusList"
                :key="index"
              >
                <div class="status" @click="onSelectStatus(item)">
                  {{ item.Name }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template slot-scope="scope">{{
          scope.row.Status == 0
            ? "待分配"
            : scope.row.Status == 1
            ? "待处理"
            : "已处理"
        }}</template>
      </el-table-column>
      <el-table-column prop="Last7DaysWorkCount" label="近7天已回访次数">
      </el-table-column>
      <el-table-column prop="" label="最近到店">
        <template slot-scope="scope">
          {{
            scope.row.LastExpeTime
              ? util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row.LastExpeTime * 1000)
                )
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="ThisMonthExpeCount"
        label="本月到店"
      ></el-table-column>
      <el-table-column prop="" label="最近回访">
        <template slot-scope="scope">
          {{
            scope.row.LastWorkTime
              ? util.dateFormat(
                  "YYYY-mm-dd",
                  new Date(scope.row.LastWorkTime * 1000)
                )
              : ""
          }}
        </template></el-table-column
      >
      <el-table-column label="操作" :width="nowCategory == 0 ? '180' : ''">
        <template slot-scope="scope">
          <!-- <el-button
            v-if="nowCategory == 0"
            type="success"
            size="mini"
            @click="assignAccepter(scope.row)"
            >确认分配</el-button
          > -->
          <el-button type="primary" size="mini" @click="onShowDetail(scope.row)"
            >详情</el-button
          >
          <el-button
            v-if="nowCategory == 0"
            type="danger"
            size="mini"
            @click="deleteWork(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- end 积分兑换记录 -->

    <!-- start 分页器 -->
    <el-pagination
      background
      :page-sizes="[5, 10, 15, 20]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!--end 分页器-->

    <AddDialog
      ref="addDialog"
      :emplList="emplList.slice(1)"
      @success="onSuccess"
    ></AddDialog>

    <FollowDetailDialog
      ref="followDetailDialog"
      :emplList="emplList.slice(1)"
      @success="onSuccess"
    ></FollowDetailDialog>
  </div>
</template>

<script>
import Pendingwork from "@/api/pendingwork.js";

import FollowDetailDialog from "./components/follow-detail-dialog.vue";
import AddDialog from "./components/add-dialog.vue";

export default {
  components: {
    FollowDetailDialog,
    AddDialog,
  },

  data() {
    return {
      authType: "",
      tableMaxHeight: "",
      nowCategory: 1,
      totalRecord: 0, // 分页器总页数
      submitData: {
        begin_date: "",
        end_date: "",
        work_type: 0,
        status: 0,
        empl_guid: "",
        page_index: 1,
        page_size: 15,
      },
      multipleDate: [],
      storesData: [],
      categoryList: [],
      statusList: [{ Name: "", Value: 0 }],
      emplList: [],
      workTypeList: [],
      tableData: [],
      loading: false,
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.storesData = accountInfo.manageStoresList;
    this.multipleDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.submitData.begin_date = this.util.getNowMonthToday();
    this.submitData.end_date = this.util.getNowMonthToday();

    this.initCustomerViewList();
    this.getCustomerViewList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 140 + 68 + 32);
    },

    // 初始化客情数据
    async initCustomerViewList() {
      try {
        let { data } = await Pendingwork.initCustomerViewList({
          begin_date: this.submitData.begin_date,
          end_date: this.submitData.end_date,
        });

        this.statusList = data.statusList;
        this.emplList = data.emplList;
        this.workTypeList = data.workTypeList;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取待办事项列表
    async getCustomerViewList() {
      let iconList = [
          "dfWebIcon-xiaofeizhouqi",
          "dfWebIcon-a-shouhou3",
          "dfWebIcon-yunteng-yujingzhongxin-yujingjieguo",
          "dfWebIcon-kehushengri",
          "dfWebIcon-huiyuanquanyi1",
          "dfWebIcon-keqingguanli",
        ],
        colorList = ["#409EFF", "#aa55fa", "#F56C6C", "#67C23A", "#08BFEA", "#E6A23C"];

      try {
        let { data, errcode } = await Pendingwork.getCustomerViewList(
          this.submitData
        );

        if (data.categoryList != null) {
          await data.categoryList.forEach((item, index) => {
            // if (index != 0) {
            item.Icon = iconList[index];
            item.Color = colorList[index];
            // }
          });
        }

        // console.log(data.categoryList);

        if (data != null && errcode == 0) {
          this.tableData = data.list;
          this.totalRecord = data.filter.TotalRecord;
          this.nowCategory = JSON.parse(JSON.stringify(this.submitData.status));
        }
        this.categoryList = data.categoryList || this.categoryList;
      } catch (err) {
        console.log(err);
      }
    },

    // 分配人员
    // async assignAccepter(event) {
    //   try {
    //     let { errcode } = await Pendingwork.assignAccepter({
    //       work_guid: event.WorkGuid,
    //       empl_guid: event.AccepterGuid,
    //       assign: 1,
    //     });
    //     if (errcode == 0) {
    //       this.$message.success(`分配成功`);
    //       this.getCustomerViewList();
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },

    // 删除待办事项
    deleteWork(event) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Pendingwork.deleteWork({
            work_guid: event.WorkGuid,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.getCustomerViewList();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    onSuccess() {
      this.initCustomerViewList();
      this.getCustomerViewList();
    },

    onSearch() {
      // this.submitData.page_index = 1;
      this.getCustomerViewList();
    },

    onSelectType(event) {
      if (event == this.submitData.work_type) {
        event = 0;
      }
      this.submitData.work_type = event;
      this.onSearch();
    },

    onSelectEmpl(event) {
      this.submitData.empl_guid = event.EmplGuid;
      this.onSearch();
    },

    onSelectStatus(event) {
      this.submitData.status = event.Value;
      this.onSearch();
    },

    onShowAdd() {
      this.$refs.addDialog.onShowDialog();
    },

    // 日期时间改变事件
    async onChangeDate() {
      this.submitData.begin_date =
        this.multipleDate.length > 0
          ? this.multipleDate[0]
          : this.util.getNowMonthToday();
      this.submitData.end_date =
        this.multipleDate.length > 0
          ? this.multipleDate[1]
          : this.util.getNowMonthToday();
      // this.submitData.page_index = 1;
      // await this.initCustomerViewList();
      await this.getCustomerViewList();
    },

    onShowDetail(event) {
      this.$refs.followDetailDialog.onShowDialog(event);
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      this.getCustomerViewList();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      this.getCustomerViewList();
    },
  },
};
</script>

<style lang="less" scoped>
.member-manage {
  .df-row {
    margin: 0 0 20px 0;

    .df-col {
      // margin-right: 10px;
    }

    .type-item {
      .flex-col;
      flex: 1;
      height: 120px;
      box-sizing: border-box;
      border: 1px solid white;
      border-top: 0;
      border-bottom: 1px solid #e8e8e8;
      cursor: pointer;

      .top {
        width: 100%;
        height: 4px;
      }

      .content-box {
        .flex-row;
        justify-content: center;
        flex: 1;
        width: 100%;

        > div {
          .flex-col;
          // flex: 1;
          padding: 0 20px;
          box-sizing: border-box;

          .dfWebIcon {
            font-size: 40px;
            margin: 5px 0;
          }

          .name {
            font-size: 14px;
            font-weight: bold;
          }

          .item {
            .flex-row;
            align-items: unset;
            margin: 2px 0;
            .title {
              color: #999;
            }

            .value {
              margin-left: 10px;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }

        .right {
          // flex: 1.1;
          align-items: unset;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    .active {
      border-color: #e8e8e8;
      // border-top: 0;
      border-bottom-color: white;
      .top {
        background-color: @primary-color;
      }

      .content-box {
        background-color: rgba(0, 0, 0, 0.02);
      }

      &:hover {
        .content-box {
          background-color: unset;
        }
      }
    }
  }

  .el-table {
  }

  .el-pagination {
    margin-top: 20px;
    float: right;
  }
}
.el-dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
  .el-dropdown-menu__item {
    padding: 0;
    .status {
      // width: 100%;
      height: 100%;
      padding: 0 20px;
    }
  }
}
.scrollbar;
</style>
